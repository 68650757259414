html {
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-image: linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%);
    min-height: 100%;
    padding-bottom: 5rem;
}

header {
    margin-top: 3rem;
}

header h1 {
    color: white;
}

.lista-citas .media {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 3rem;
}

.lista-citas .media:last-of-type {
    border-bottom: 0;
}

.lista-citas .media-body p {
    margin: 0;
}

.lista-citas .media-body button {
    margin-top: 1rem!important;
}

.lista-citas .media-body span {
    font-weight: bold;
}

.col-form-label {
    font-size: 12px;
}